import React from 'react'
import './SubHeading.css'

const SubHeading = (props) => {
    return (
       
            <div className="Subheading" ><h2>{props.title}</h2></div>
      
    )
}

export default SubHeading